<template>
  <div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'

export default {
  mounted() {
    const queryParams = new URLSearchParams(this.$route.query)
    let encryptedData = queryParams.get('data')
    console.log('mounted: ' + encryptedData)

    if (encryptedData) {
      encryptedData = encryptedData.replace(/ /g, '+')

      try {
        const decryptedData = this.decryptAES(encryptedData)
        if (decryptedData.includes('account')) {
          let obj = JSON.parse(decryptedData)
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty('account')) {
            sessionStorage.setItem('account', obj.account)
          }
        }
      } catch (error) {
        console.error('Decryption failed:', error)
      }
    }

    window.location.replace('./')
  },
  methods: {
    decryptAES(encryptedData) {
      const decodedEncryptedData = CryptoJS.enc.Base64.parse(encryptedData)

      // IV 和密鑰都要用 UTF-8 編碼解析
      const iv = CryptoJS.enc.Utf8.parse('g8W2exnkNdtec7HP')
      const key = CryptoJS.enc.Utf8.parse('xwG859HkEkVrTeNAzra14ZJCw8eM3lE6')

      // 解密操作，輸入 Base64 的加密字串
      const decrypted = CryptoJS.AES.decrypt(
          { ciphertext: decodedEncryptedData },
          key,
          {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
      )

      // 將解密後的字串轉換為 UTF-8 格式
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8)
      return decryptedText
    }
  }
}
</script>
